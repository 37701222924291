/* eslint-disable max-len */
import React, {
    useCallback, useState, useContext, useEffect
} from 'react';
import appConfig from 'app-config';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Image } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { useAuthentication } from 'gw-digital-auth-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { CustomCRMService } from 'gw-capability-profileinfo';
import { CustomPolicySummaryContext } from 'gw-capability-policy-react';
import { EnrollmentSummaryContext } from 'gw-capability-enrollment-react';
import { CustomIconUtil, CustomValidatorUtil } from 'gw-portals-util-js';
import metadata from './EnrollmentComponent.metadata.json5';
import styles from './EnrollmentComponent.module.scss';
import messages from './EnrollmentComponent.messages';

const ENROLLMENT_ERRORS = {
    enrollmentInvalid: 'enrollmentInvalid',
    enrollmentFailed: 'enrollmentFailed',
    fieldEmpty: 'fieldEmpty'
};

function EnrollmentComponent(props) {
    const translator = useContext(TranslatorContext);
    const [formData, updateFormData] = useState({});
    const [enrollmentStatus, setEnrollmentStatus] = useState();
    const [availablePolicies, setAvailablePolicies] = useState([]);
    const [isLoading, setLoadingState] = useState(false);
    const [enrollmentError, setEnrollmentError] = useState();
    const [enrollmentInvalidError, setEnrollmentInvalidError] = useState();
    const { authHeader, refreshToken, oid, email, strongAuthenticationPhoneNumber, given_name, family_name } = useAuthentication();
    const { EnrollmentService } = useDependencies('EnrollmentService');
    const [lastNameValidationMessages, setLastNameValidationMessages] = useState([]);
    const [firstNameValidationMessages, setFirstNameValidationMessages] = useState([]);
    const [businessNameValidationMessages, setBusinessNameValidationMessages] = useState([]);
    const [policyNumberValidationMessages, setPolicyNumberValidationMessages] = useState([]);
    const [zipCodeValidationMessages, setZipCodeValidationMessages] = useState([]);
    const policySummary = useContext(CustomPolicySummaryContext);
    const prefSummary = useContext(EnrollmentSummaryContext);
    let hasEnrollment = true;
    const {
        enrollmentType,
        onSuccessEnrollment,
        informationAdditionalText,
        onReturnLinkClick,
        enrollButtonText
    } = props;

    const isEnrolled = useCallback(() => {
        if (prefSummary.data === undefined || prefSummary.data.profile === undefined) {
            hasEnrollment = false;
            return false;
        }

        if (prefSummary.data.profile.enrollments.length === 0) {
            hasEnrollment = false;
        }
        return true;
    });

    const enrollmentTypeIndividual = useCallback(() => {
        if (isEnrolled()) {
            const { typeOfPolicy } = formData;
            const prefSummaryProfile = prefSummary.data.profile;
            const nullCheckEnrollmentType = (hasEnrollment && prefSummaryProfile.enrollments[0].enrollmentType);
            const type = nullCheckEnrollmentType ? prefSummaryProfile.enrollments[0].enrollmentType
                : typeOfPolicy;
            return _.lowerCase(type);
        }
        return null;
    });

    const isIndividualPolicy = useCallback(() => {
        const { typeOfPolicy } = formData;
        return typeOfPolicy === 'individual';
    }, [formData]);

    const getFirstName = useCallback(() => {
        if (isEnrolled()) {
            return prefSummary.data.profile.contact.firstName;
        }
        return null;
    });

    const getLastName = useCallback(() => {
        if (isEnrolled()) {
            return prefSummary.data.profile.contact.lastName;
        }
        return null;
    });

    const getPrimaryNameInsured = useCallback(() => {
        if (isEnrolled()) {
            const prefSummaryProfile = prefSummary.data.profile;
            const nullCheckPNI = (hasEnrollment && prefSummaryProfile.enrollments[0].primaryNamedInsured !== null);
            const primaryNamedInsured = nullCheckPNI ? prefSummaryProfile.enrollments[0].primaryNamedInsured
                : (`${prefSummaryProfile.contact.firstName} ${prefSummaryProfile.contact.lastName}`);
            return primaryNamedInsured;
        }
        return null;
    });

    function hasValidationErrors(fieldValidationMessages) {
        if (!_.isEmpty(fieldValidationMessages) && fieldValidationMessages[0] !== undefined && fieldValidationMessages[0] !== null) {
            return true;
        }

        return false;
    }

    const allFieldsEntered = useCallback(() => {
        const {
            policyNumber, zipCode, businessName, firstName, lastName
        } = formData;

        if (hasValidationErrors(firstNameValidationMessages) || hasValidationErrors(lastNameValidationMessages) || hasValidationErrors(businessNameValidationMessages) || hasValidationErrors(policyNumberValidationMessages) || hasValidationErrors(zipCodeValidationMessages)) {
            return true;
        }

        if (isEnrolled() || !hasEnrollment) {
            if (_.isEmpty(policyNumber) || _.isEmpty(zipCode)) {
                return true;
            }
            return false;
        }

        if (isIndividualPolicy()) {
            if (_.isEmpty(policyNumber) || _.isEmpty(zipCode) || _.isEmpty(firstName) || _.isEmpty(lastName)) {
                return true;
            }
        } else if (_.isEmpty(policyNumber) || _.isEmpty(zipCode) || _.isEmpty(businessName)) {
            return true;
        }

        return false;
    }, [formData, firstNameValidationMessages, lastNameValidationMessages, businessNameValidationMessages, policyNumberValidationMessages, zipCodeValidationMessages]);

    function handleEnrollmentError(errorMsg) {
        setEnrollmentInvalidError(true);
        setEnrollmentError(errorMsg || messages.enrollmentFailed.defaultMessage);
        setEnrollmentStatus(ENROLLMENT_ERRORS.enrollmentFailed);
    }

    const getEnrollmentDetails = () => {
        const { policyNumber, zipCode } = formData;
        return {
            details: {
                typeOfPolicy: enrollmentTypeIndividual(),
                policyNumber: policyNumber,
                zipCode: zipCode,
                businessName: getPrimaryNameInsured(),
                firstName: getFirstName(),
                lastName: getLastName(),
                ciamId: oid,
                email: email,
                phone: strongAuthenticationPhoneNumber
            },
            type: enrollmentType
        };
    };

    const enrollPolicy = useCallback(
        (evt) => {
            if (evt) { evt.preventDefault(); }
            setLoadingState(true);
            return EnrollmentService.addEnrollmentRecord_Ext(
                getEnrollmentDetails(),
                authHeader
            ).then((resp) => {
                refreshToken().then(() => {
                    setLoadingState(false);
                    if (onSuccessEnrollment) {
                        onSuccessEnrollment();
                    } else {
                        updateFormData({});
                        setAvailablePolicies(resp);
                        setEnrollmentStatus('enrollmentRequestSucceeded');
                        policySummary.updateCall(true);
                    }
                });
            }).catch((error) => {
                setLoadingState(false);
                setAvailablePolicies([]);
                if (error) {
                    let errorMsg = error.baseError.substring(error.baseError.lastIndexOf('ErrorMessage:') + 14);
                    if (errorMsg.includes('connected to another account')) {
                        errorMsg = errorMsg.substring(errorMsg.indexOf('.') + 1);
                        setEnrollmentInvalidError(false);
                    } else {
                        handleEnrollmentError(errorMsg);
                    }
                }
            });
        },
        [EnrollmentService, authHeader, enrollmentType, formData, onSuccessEnrollment, refreshToken]
    );

    const enrollUser = useCallback(async (evt) => {
        if (evt) {
            evt.preventDefault();
        }
        const { typeOfPolicy, policyNumber, zipCode, businessName, firstName, lastName } = formData;

        setLoadingState(true);

        try {
            const verifyCall = await CustomCRMService.verifyPolicy(
                authHeader,
                appConfig.env.CRM_VERIFY_POLICY,
                //1671079 - PHSS_Re-enrollment page_Invalid enrollment request when trying to re-enroll business acct
                { policyNumber, postalCode: zipCode, firstName: given_name, lastName: family_name, companyName: businessName }
            );

            if (verifyCall.canEnroll) {
                const enrollResp = await CustomCRMService.enrollUser(
                    authHeader,
                    appConfig.env.CRM_ENROLL_USER_PATH,
                    {
                        enrollmentType: typeOfPolicy,
                        policyNumber: policyNumber,
                        riskState: '',
                        product: '',
                        organization: _.isEmpty(businessName) ? '' : businessName,
                        firstName: _.isEmpty(given_name) ? '' : given_name,
                        lastName: _.isEmpty(family_name) ? '' : family_name,
                        ciamId: oid,
                        emailAddress: email,
                        mobilePhone: strongAuthenticationPhoneNumber
                    }
                );

                if (enrollResp.enrolled) {
                    await refreshToken();
                    onSuccessEnrollment();
                } else {
                    handleEnrollmentError();
                }
            } else {
                handleEnrollmentError(verifyCall.errorMessage);
            }
        } catch (error) {
            handleEnrollmentError(error);
        }

        setLoadingState(false);
    });


    const writeValue = useCallback(
        (value, path) => updateFormData({
            ...formData,
            [path]: value
        }),
        [formData]
    );

    const validationCheck = useCallback((inputValue, inputValidator) => {
        const newValue = inputValue.currentTarget.value;
        switch (inputValidator.id) {
            case 'firstName':
                setFirstNameValidationMessages([CustomValidatorUtil.nameValidation(newValue)]);
                break;
            case 'lastName':
                setLastNameValidationMessages([CustomValidatorUtil.nameValidation(newValue)]);
                break;
            case 'businessName':
                setBusinessNameValidationMessages([CustomValidatorUtil.businessNameValidation(newValue)]);
                break;
            case 'policyNumber':
                setPolicyNumberValidationMessages([CustomValidatorUtil.policyNumberValidation(newValue)]);
                break;
            case 'zipCode':
                setZipCodeValidationMessages([CustomValidatorUtil.zipCodeValidation(newValue)]);
                break;
            default:
                break;
        }
    });

    useEffect(() => {
        if (prefSummary.data.length === 0 || !_.isEmpty(prefSummary.data.success)) {
            setLoadingState(true);
            return;
        }
        setLoadingState(false);
        window.scroll(0, 0);
    }, [prefSummary.data]);

    const overrideProps = {
        policyType: {
            visible: isEnrolled(),
            value: (isEnrolled() && enrollmentTypeIndividual() === 'individual') ? translator(messages.policyTypeIndividual) : translator(messages.policyTypeBusiness)
        },
        primaryNameInsured: {
            visible: isEnrolled(),
            value: isEnrolled() ? getPrimaryNameInsured() : null
        },
        enterDetailsHeader: {
            content: !isEnrolled() || !hasEnrollment ? translator(messages.reenrollAPolicy) : translator(messages.addAPolicy)
        },
        enterPolicyDetailsInformationText: {
            content: !isEnrolled() || !hasEnrollment ? translator(messages.enterPolicyError) : translator(messages.enterPolicyDetails)
        },
        verificationInformationAdditionalText: {
            content: translator(informationAdditionalText)
        },
        enrollmentErrorMessageContainer: {
            visible:
                !_.isUndefined(enrollmentStatus)
                && enrollmentStatus !== 'enrollmentRequestSucceeded'
        },
        enrollmentErrorMessage: {
            message: enrollmentError,
            messageProps: enrollmentInvalidError ? { error: translator(messages.inValidRequest) } : { error: translator(messages.connectedPolicy) }
        },
        enrollmentSuccessNotificationMessage: {
            message: 'You now have access to your policy.'
        },
        enrollmentSuccessNotificationContainer: {
            visible: enrollmentStatus === 'enrollmentRequestSucceeded'
        },
        returnLink: {
            visible: isEnrolled() && hasEnrollment,
            to: _.isUndefined(onReturnLinkClick) ? '/home' : '#',
            onClick: onReturnLinkClick
        },
        enrollButton: {
            content: translator(enrollButtonText),
            trigger: (!isEnrolled() ? enrollUser : enrollPolicy),
            disabled: allFieldsEntered()
        },
        typeOfPolicyButton: {
            visible: !isEnrolled(),
            className: styles.policyTypes
        },
        businessName: {
            visible: !isEnrolled() && !isIndividualPolicy(),
            onBlur: validationCheck,
            showErrors: hasValidationErrors(businessNameValidationMessages),
            validationMessages: businessNameValidationMessages.filter((n) => n)
        },
        firstName: {
            visible: !isEnrolled() && isIndividualPolicy(),
            onBlur: validationCheck,
            showErrors: hasValidationErrors(firstNameValidationMessages),
            validationMessages: firstNameValidationMessages.filter((n) => n)
        },
        lastName: {
            visible: !isEnrolled() && isIndividualPolicy(),
            onBlur: validationCheck,
            showErrors: hasValidationErrors(lastNameValidationMessages),
            validationMessages: lastNameValidationMessages.filter((n) => n)
        },
        policyNumber: {
            onBlur: validationCheck,
            showErrors: hasValidationErrors(policyNumberValidationMessages),
            validationMessages: policyNumberValidationMessages.filter((n) => n)
        },
        zipCode: {
            onBlur: validationCheck,
            showErrors: hasValidationErrors(zipCodeValidationMessages),
            validationMessages: zipCodeValidationMessages.filter((n) => n)
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
    };

    if (isLoading) {
        return <Image className={styles.spinner} src={CustomIconUtil.getLogoSrc('rotating-circle-blue_128.gif')} showLoader={false} />;
    }

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={formData}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

EnrollmentComponent.propTypes = {
    enrollmentType: PropTypes.string.isRequired,
    onSuccessEnrollment: PropTypes.func,
    onReturnLinkClick: PropTypes.func,
    returnLinkText: PropTypes.shape({
        id: PropTypes.string,
        defaultMessage: PropTypes.string
    }),
    enrollButtonText: PropTypes.shape({
        id: PropTypes.string,
        defaultMessage: PropTypes.string
    }),
    informationText: PropTypes.shape({
        id: PropTypes.string,
        defaultMessage: PropTypes.string
    }).isRequired,
    informationAdditionalText: PropTypes.shape({
        id: PropTypes.string,
        defaultMessage: PropTypes.string
    }).isRequired
};

EnrollmentComponent.defaultProps = {
    onSuccessEnrollment: undefined,
    onReturnLinkClick: undefined,
    returnLinkText: messages.returnToHomePage,
    enrollButtonText: messages.enroll
};

export default EnrollmentComponent;
