import React, {
    useContext,
    useState,
    useEffect,
    useCallback
} from 'react';
import { ButtonLink, NavRoutes } from '@jutro/router';
import { Switch, Route, Redirect } from 'react-router-dom';
import _ from 'lodash';
// Imported First to allow Jutro styles to be overridden
import './App.scss';
import { setComponentMapOverrides } from '@jutro/uiconfig';
import { Main } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { DynamicRoute, GrantedAuthorities, withAuthContextProvider, useAuthentication } from 'gw-digital-auth-react';
import vmTranslator from 'gw-platform-translations';
import { AccurateBreakpointPropagation } from 'gw-jutro-adapters-react';
import { EnrollmentService } from 'gw-capability-policyenrollment';
import { ViewModelServiceFactory } from 'gw-portals-viewmodel-js';
import { PolicyDetails, CustomPolicySummaryContext, LoadingContext } from 'gw-capability-policy-react';
import { AvailabilityService } from 'gw-capability-policycommon';
import { EndorsementService } from 'gw-capability-policychange';
import { PAPolicyChangeWizard } from 'gw-capability-policychange-pa-react';
import { HOPolicyChangeWizard } from 'gw-capability-policychange-ho-react';
import { LoginCapabilityComponentMap, LoginCapabilityComponents } from 'gw-portals-login-react';
import { personalizedOfferComponentMap, personalizedOfferComponents } from 'gw-capability-personalizedoffers-react';
import { VehicleInfoLookupComponentMap, VehicleInfoLookupComponents } from 'gw-capability-vehicleinfo-react';
import { policyJobComponentMap, policyJobComponents } from 'gw-capability-policyjob-react';
import {
    BillingSummaryPage,
    MakePaymentPage,
    billingComponentMap,
    billingAdditionalComponents,
    CustomChoosePymtPage
} from 'gw-capability-billing-react';
import { AdditionalEnrollment, SignUpEnrollment, EnrollmentSummaryContext } from 'gw-capability-enrollment-react';
import { UsersProfile, CustomAccountPreferences, CustomAccountPrefModal, CustomAccountPrefContext } from 'gw-capability-profileinfo-react';
import { WizardPageTemplateWithTitle } from 'gw-portals-wizard-react';
import { ProducerInfoService, CustomCRMService } from 'gw-capability-profileinfo';
import { DependencyProvider } from 'gw-portals-dependency-react';
import { AccountBillingDetailsService, BillingService } from 'gw-capability-billing';
import { PolicyService } from 'gw-capability-policy';
import { ClaimDocumentService } from 'gw-capability-claimdocument';
import { ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import {
    platformComponentMap,
    platformComponents,
    ImageComponent,
} from 'gw-components-platform-react';
import { ErrorBoundary } from 'gw-portals-error-react';
import BaseFNOLWizard, { ClaimsConfirmationPage } from 'gw-capability-fnol-common-react';
import { ContactAgentPage } from 'gw-pages-platform-react';
import FNOLPAWizard from 'gw-capability-fnol-pa-react';
import FNOLWCWizard from 'gw-capability-fnol-wc-react';
import FNOLCAWizard from 'gw-capability-fnol-ca-react';
import FNOLHOWizard from 'gw-capability-fnol-ho-react';
import FNOLCPWizard from 'gw-capability-fnol-cp-react';
import FNOLBOPWizard from 'gw-capability-fnol-bop-react';
import FNOLGeneralWizard from 'gw-capability-fnol-gl-react';
import {
    CustomClaimCardList, CustomClaimDetails, CreateNote, PolicyLevelCoverages
} from 'gw-capability-claim-react';
import { ClaimService } from 'gw-capability-claim';
import { FNOLService } from 'gw-capability-fnol';
import FaqPage from 'gw-capability-faq-react';
import { PolicyDocumentError } from 'gw-capability-document-react';
import { filterCapabilityRoutes } from 'gw-portals-config-js';
import { getGrantedAuthorities } from 'gw-portals-auth-js/utils/GrantedAuthoritiesUtil';
import { CustomIconUtil } from 'gw-portals-util-js';
import { Image } from '@jutro/components';
import CustomHeaderComponent from '../components/CustomHeaderComponent/CustomHeaderComponent';
import CustomFooterComponent from '../components/CustomFooterComponent/CustomFooterComponent';
import { routes } from './App.routes.metadata.json5';
import ResetPasswordPage from '../pages/ResetPasswordPage/ResetPasswordPage';
import EntryPage from '../pages/EntryPage/EntryPage';
import LandingPage from '../pages/LandingPage/LandingPage';
import messages from './App.messages';
import CustomStickyFlyouts from '../components/CustomStickyFlyouts/CustomStickyFlyouts';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights, logError } from '../components/AppInsightsComponent/AppInsightsComponent';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

const { capabilitiesConfig } = appConfig;

const componentMap = {
    landingpage: LandingPage,
    policydocumenterror: PolicyDocumentError,
    fnolwcwizard: FNOLWCWizard,
    basefnolwizard: BaseFNOLWizard,
    claimsconfirmationpage: ClaimsConfirmationPage,
    contactagent: ContactAgentPage,
    fnolpawizard: FNOLPAWizard,
    fnolhowizard: FNOLHOWizard,
    additionalenrollmentpage: AdditionalEnrollment,
    claimlist: CustomClaimCardList,
    claimdetails: CustomClaimDetails,
    policydetailspage: PolicyDetails,
    papolicychangewizard: PAPolicyChangeWizard,
    hopolicychangewizard: HOPolicyChangeWizard,
    createnote: CreateNote,
    billingsummarypage: BillingSummaryPage,
    makepaymentpage: MakePaymentPage,
    usersprofile: UsersProfile,
    policylevelcoverages: PolicyLevelCoverages,
    faqpage: FaqPage,
    fnolcawizard: FNOLCAWizard,
    fnolcpwizard: FNOLCPWizard,
    fnolbopwizard: FNOLBOPWizard,
    fnolgeneralwizard: FNOLGeneralWizard,
    customaccountpref: CustomAccountPreferences
};

const checkSignupSuccess = (authorities) => {
    const accountAuthorities = authorities.filter((authority) => authority.authorityType === 'POLICY');
    if (accountAuthorities.length === 0) {
        return <Redirect to="/signup-enrollment" />;
    }
    return null;
};

setComponentMapOverrides(
    {
        ...platformComponentMap,
        ...personalizedOfferComponentMap,
        ...VehicleInfoLookupComponentMap,
        ...LoginCapabilityComponentMap,
        ...policyJobComponentMap,
        ...billingComponentMap,
        WizardPageTemplateWithTitle: { component: 'WizardPageTemplateWithTitle' },
        // replace the native IMG component with a proxied version
        img: { component: 'img' },
    },
    {
        ...platformComponents,
        ...personalizedOfferComponents,
        ...VehicleInfoLookupComponents,
        ...LoginCapabilityComponents,
        ...policyJobComponents,
        ...billingAdditionalComponents,
        WizardPageTemplateWithTitle,
        img: ImageComponent,
    }
);

// (Router) Title is required and used for (Browser) title
// in a format of "${title} - ${appName}". In order to show
// the application name only, it needs to be an empty value.
const routesWithTitle = filterCapabilityRoutes(capabilitiesConfig, routes);
const routesWithoutTitle = routesWithTitle.map((route) => {
    if (route.title === undefined) {
        return {
            ...route,
            title: ''
        };
    }
    return {
        ...route
    };
});

function App() {
    const translator = useContext(TranslatorContext);
    const [viewModelService, setViewModelService] = useState(undefined);
    const [accountPolicyData, setAccountPolicyData] = useState([]);
    const [accountPrefData, setAccountPrefData] = useState([]);
    const {
        accessToken, authHeader, refreshToken, oid,
        email, strongAuthenticationPhoneNumber, grantedAuthorities
    } = useAuthentication();
    const [accountPolicyCall, setAccountPolicyCall] = useState(true);
    const [refreshCall, setRefreshCall] = useState(undefined);
    const [cardBeingEdited, setCardBeingEdited] = useState(false);
    const [showUnsavedChangesBanner, setShowUnsavedChangesBanner] = useState(false);
    const [accountPrefCall, setAccountPrefCall] = useState(true);
    const [showLoading, setShowLoading] = useState(false);

    //1582415 - Added for appInsights
    appInsights.addTelemetryInitializer((env) => {
        env.tags = env.tags || {};
        env.tags["ai.cloud.role"] = appConfig.env.APPINSIGHTS_APP_NAME;
        env.data = env.data || {};
        env.data["am-app-name"] = appConfig.env.APPINSIGHTS_APP_NAME;
        env.data["am-userid"] = email;
    });

    const getAccountPolicyData = useCallback(() => {
        setAccountPolicyCall(false);
        Promise.all([
            PolicyService.getAccountPolicySummaries(authHeader).then(setAccountPolicyData)
        ]);
    }, [accountPolicyData, accountPolicyCall, refreshCall, authHeader]);

    const getAccountPrefData = useCallback(async () => {
        let contactProfileResp = await CustomCRMService.getContactProfile(
            authHeader,
            appConfig.env.CRM_GET_CONTACT_PROFILE_PATH,
            { ciamId: oid }
        ).catch((err) => { 
            //logError(err, SeverityLevel.Information, 'App.getAccountPrefData'); 
            return err; 
        });

        // 1663200 - resetting back to the success field as false for contact not found
        // not to break th profile object in subsequent calls
        if (contactProfileResp.errorMessage === 'Contact not found.') {
            contactProfileResp.success = false;
        }        
       
        if (contactProfileResp !== undefined && contactProfileResp.success) {
            if (_.isEmpty(contactProfileResp.profile.contact.mobilePhone)) {
                Promise.all([CustomCRMService.updateAccount(authHeader, appConfig.env.CRM_UPDATE_ACCOUNT_PATH, { ciamId: oid, emailAddress: email, updatedMobilePhone: strongAuthenticationPhoneNumber })]).catch((err) => { return err; });
            }
            setAccountPrefData(contactProfileResp);
        } else if (contactProfileResp === undefined || contactProfileResp.errorMessage !== undefined) {
            setAccountPrefData({ success: false });
        }

        if (contactProfileResp.errorMessage === 'Contact not found.') {
            <Redirect to="/signup-enrollment" />;
        }
    }, [accountPrefData, refreshCall, authHeader]);

    const syncAndRefresh = useCallback(async () => {
        if (authHeader !== undefined && accountPolicyCall === true && refreshCall === undefined) {
            setRefreshCall('getPoliciesCalled');
            let enrolled = false;
            const policiesToSync = await EnrollmentService.getPoliciesToSync(
                {
                    details: {
                        ciamId: oid,
                        email: email,
                        phone: strongAuthenticationPhoneNumber,
                    },
                },
                grantedAuthorities,
                authHeader
            );

            if (policiesToSync !== undefined && policiesToSync.length === 0) { setRefreshCall(false); }

            policiesToSync.forEach(async (policy, index, arry) => {
                const canEnrollPolicyData = {
                    emailAddress: policy.emailAddress,
                    policyNumber: policy.policyNumber
                };
                const verifyCall = await CustomCRMService.verifyUserPolicy(authHeader, appConfig.env.CRM_VERIFY_USER_POLICY, canEnrollPolicyData);
                if (verifyCall.canEnroll) {
                    const addEnrollmentData = {
                        ciamId: policy.ciamId,
                        emailAddress: policy.emailAddress,
                        policyNumber: policy.policyNumber,
                        riskState: policy.riskState,
                        namedInsured: policy.namedInsured,
                        mobilePhone: policy.mobilePhone,
                        product: policy.product,
                        enrollmentType: policy.enrollmentType
                    };
                    await CustomCRMService.addEnrollment(authHeader, appConfig.env.CRM_ADD_ENROLLMENT, JSON.stringify(addEnrollmentData));
                    enrolled = true;
                }

                // execute refreshCall after last policy. only refresh if enrollment happened.
                if (arry.length - 1 === index) { setRefreshCall(enrolled);if (enrolled) refreshToken(); }
            });
        }
    }, [authHeader, accountPolicyCall]);

    useEffect(() => {
        const doSyncPolicyCall = accountPolicyCall === true && refreshCall === true && authHeader !== undefined
        && getGrantedAuthorities({ accessToken: accessToken }).length > grantedAuthorities.length;
        const doDefaultPolicyCall = accountPolicyCall === true && refreshCall === false && authHeader !== undefined;
        if (doSyncPolicyCall || doDefaultPolicyCall) {
            getAccountPrefData();
            getAccountPolicyData();
        }
    }, [refreshCall, authHeader, accountPolicyCall]);

    const handleError = useCallback(() => {
        window.scroll(0,0);
        return (
            <div className="error-page-overlay">
                <div className="error-page-content">
                    <img alt="missing-page" className="error-page-image" />
                    <h1 className="error-page-header">{translator(messages.errorMessageHeader)}</h1>
                    <div className="error-page-message">
                        {translator(messages.errorMessageContent)}
                    </div>
                    <ButtonLink className="error-page-button" href="/home">{translator(messages.returnButton)}</ButtonLink>
                </div>
            </div>
        );
    }, [translator]);

    useEffect(() => {
        const translatorFn = vmTranslator(translator);
        if (oid !== undefined) {
            window.postMessage(['adobeCiamUpdate', oid], '*');
        }
        import(
            /* webpackChunkName: "product-metadata" */
            // eslint-disable-next-line import/no-unresolved
            'product-metadata'
        ).then((productMetadata) => {
            const { default: result } = productMetadata;
            setViewModelService(
                ViewModelServiceFactory.getViewModelService(result, translatorFn)
            );
        });
        syncAndRefresh();
    }, [translator]);

    return (
    <AppInsightsContext.Provider value={reactPlugin}>
        <AccurateBreakpointPropagation>
            <DependencyProvider
                value={{
                    AccountBillingDetailsService: AccountBillingDetailsService,
                    PolicyService: PolicyService,
                    EndorsementService: EndorsementService,
                    AvailabilityService: AvailabilityService,
                    FNOLService: FNOLService,
                    ClaimService: ClaimService,
                    ClaimDownloadService: ClaimDocumentService,
                    BillingService: BillingService,
                    EnrollmentService: EnrollmentService,
                    ContactService: ProducerInfoService,
                }}
            >
                <ErrorBoundary onError={handleError}>
                    <ViewModelServiceContext.Provider value={viewModelService}>
                        <CustomPolicySummaryContext.Provider value={{data:accountPolicyData, updateCall: setAccountPolicyCall}}>
                            <EnrollmentSummaryContext.Provider value={{data:accountPrefData, updateCall: setAccountPrefData}}>
                                <CustomAccountPrefContext.Provider value={{cardBeingEdited: cardBeingEdited, updateCardBeingEdited: setCardBeingEdited, showUnsavedChangesBanner:showUnsavedChangesBanner, setShowUnsavedChangesBanner: setShowUnsavedChangesBanner}}>
                                    <LoadingContext.Provider value={{loading: showLoading, setLoading: setShowLoading }}>
                                        <Switch>
                                            <Route exact path="/login-page" component={EntryPage} />
                                            <Route exact path="/auth/resetpassword" component={ResetPasswordPage} />
                                            <Route exact path="/signup-enrollment" component={SignUpEnrollment} />
                                            <Route exact path="/choose-payment/:policyNumber" component={CustomChoosePymtPage} />                                          
                                            <DynamicRoute path="/">
                                                <GrantedAuthorities
                                                    checkRenderBasedOnAuthorities={checkSignupSuccess}
                                                >
                                                    <CustomHeaderComponent routes={routesWithTitle} /> 
                                                    <div className="customLoader" style={{display:showLoading?'block':'none'}}><Image className="spinner" src={CustomIconUtil.getLogoSrc('rotating-circle-blue_128.gif')} showLoader={false} /></div>
                                                    <div style={{display:showLoading?'none':'block'}}>                                                                                                  
                                                        <CustomStickyFlyouts />
                                                        <ErrorBoundary onError={handleError}>
                                                            <Main>
                                                                <NavRoutes
                                                                    resolveComponentMap={componentMap}
                                                                    routes={routesWithoutTitle}
                                                                />
                                                            </Main>
                                                        </ErrorBoundary>
                                                        <CustomFooterComponent /> 
                                                        <CustomAccountPrefModal />
                                                    </div>
                                                    
                                                </GrantedAuthorities>
                                            </DynamicRoute>
                                        </Switch>
                                    </LoadingContext.Provider>
                                </CustomAccountPrefContext.Provider>
                            </EnrollmentSummaryContext.Provider>
                        </CustomPolicySummaryContext.Provider>
                    </ViewModelServiceContext.Provider>
                </ErrorBoundary>
            </DependencyProvider>
        </AccurateBreakpointPropagation>
    </AppInsightsContext.Provider>
    );
}

export default withAuthContextProvider()(App);
